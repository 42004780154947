* {
    box-sizing: border-box;
}

html {
    height: 100dvh;
    width: 100dvw;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#root {
    height: 100%;
    width: 100%;
}

#root>div {
    height: 100%;
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

/* restaurant-index.html styling */

.mobile-header-container {
    height: 10%;
    width: 100%;
    display: flex;
    background-color: #8E2121;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid black;
}

.mobile-header-item:nth-of-type(1) {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 10px;
}

.mobile-header-item:nth-of-type(2) {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 10px
}

.mobile-header-item:nth-of-type(3) {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 10px;
}

#toggle-button {
    background-color: #8E2121;
    border: none;
    margin: none;
    padding: none;
}

#close-icon {
    font-size: 25px;
    color: white;
}

#close-landing-page-sidebar-button, #close-sidebar-button {
    background-color: #4B1010;
    border: none;
}

.landing-page-sidebar, .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    z-index: 999;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.landing-page-sidebar-item:nth-of-type(1), .sidebar-item:nth-of-type(1) {
    height: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
    background-color: #4B1010;
    border-top-left-radius: 10px;
}

.landing-page-sidebar-item:nth-of-type(2), .sidebar-item:nth-of-type(2) {
    height: 50%;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #8E2121;
}

.landing-page-sidebar-item:nth-of-type(3), .sidebar-item:nth-of-type(3) {
    height: 35%;
    padding-left: 50px;
    background-color: #8E2121;
}

.landing-page-sidebar-item:nth-of-type(4) {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #4B1010;
    border-bottom-left-radius: 10px;
}

.landing-page-sidebar ul, .sidebar ul {
    list-style: none;
    padding: 0;
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.landing-page-sidebar li, .sidebar li {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    padding-left: 50px;
    display: flex;
    align-items: center;
    margin: 0;
}

.landing-page-sidebar li:hover, .sidebar li:hover {
    background-color: #4B1010;
}

.landing-page-sidebar a, .sidebar a {
    text-decoration: none;
    color: white;
}

#sign-in-button {
    width: 95px;
    height: 35px;
    font-size: 16px;
    background-color: white;
    color: #4B1010;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
}

#sign-in-button:hover {
    background-color: #4B1010;
    color: white;
}

#log-out-button {
    width: 95px;
    height: 35px;
    font-size: 16px;
    background-color: white;
    color: #4B1010;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
}

#log-out-button:hover {
    background-color: #4B1010;
    border: 1px solid black;
    color: white;
}

#logo {
    max-height: 60%;
    max-width: 60%;
}

#hamburger-menu {
    height: 22px;
    width: 26px;
}

.desktop-header-container {
    display: none;
}

.no-footer-main-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100%;
}

.no-footer-main-item:nth-of-type(1) {
    height: 15%;
    width: 100%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.no-footer-main-item:nth-of-type(2) {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.no-footer-main-item:nth-of-type(2).landing-page-no-footer-main-item {
    justify-content: space-between;
}

.res-id-statement {
    font-size: 18px;
    margin-top: 10px;
    font-weight: normal;
}

.copyright-statement {
    margin-bottom: 15px;
}

h1 {
    margin: 0;
    padding: 0;
}

.heading:nth-of-type(1) {
    font-size: 20px;
}

.heading:nth-of-type(2) {
    font-size: 24px;
    color: #8E2121;
}

.add-points-container {
    height: 60%;
    width: 90%;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add-points-item {
    height: 25%;
    width: 80%;
    display: flex;
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #939191;
}

.add-points-item:nth-of-type(1) {
    align-items: center;
    height: 15%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: end;
}

.add-points-item:nth-of-type(4) {
    border-bottom: none;
    align-items: center;
    justify-content: center;
}

input {
    font-size: 18px;
    border: none;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
}

input:focus {
    outline: none;
    background-color: #EAEAEA;
}

#enter-button {
    width: 95px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    color: #8E2121;
    background-color: white;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
}

#enter-button:hover {
    background-color: #8E2121;
    color: white;
    border: 1px solid black;
}

.visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    white-space: nowrap;
}

/* customer-index.html styling */
.loading-page {
    font-size: 20px;
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loading-spinner {
    height: 48px;
    width: 48px;
    margin: 5px;
}

.search-container {
    position: relative;
}

#search {
    border: 1px solid #939191;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;
    width: 250px;
    height: 30px;
    position: relative;
    z-index: 1;
}

.results-list {
    background-color: white;
    border: 1px solid black;
    margin-top: 5px;
    border-radius: 5px;
    position: relative;
    z-index: 2;
}

.results-list a {
    text-decoration: none;
    color: black;
}

.search-result {
    height: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.search-result:hover {
    background-color: #8E2121;
    color: white;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.no-footer-main-subitem:nth-of-type(1) {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.no-footer-main-subitem:nth-of-type(2) {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.no-footer-main-subitem:nth-of-type(2).column-flex {
    align-items: center;
    justify-content: start;
    position: relative;
}

.no-footer-main-subitem:nth-of-type(3) {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-window {
    position: fixed;
    background-color: #f6f6f6;
    border: 1px solid #4B1010;
    top: 20%;
    left: 50%;
    margin-left: -140px;
    height: 200px;
    width: 280px;
    z-index: 499;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete-window div:nth-of-type(1) {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 18px;
    color: #8E2121;
    text-align: center;
}

.delete-window div:nth-of-type(2) {
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#yes-delete-membership-button, #no-dont-delete-membership-button {
    height: 25px;
    width: 75px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
}

#yes-delete-membership-button:hover, #no-dont-delete-membership-button:hover {
    background-color: #8E2121;
    color: white;
}

[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
    color: gray;
}

.restaurant-tab, .reward-tab, .reward-history-tab, .reward-history-tab.labels {
    min-height: 75px;
    width: 90%;
    display: flex;
    border: 1px solid #939191;
    border-radius: 10px;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 2px 2px 4px 2px #EAEAEA;
}

.reward-history-tab.labels {
    /* background-color: #8E2121; */
    color: #8E2121;
    border: 2px solid black;
    font-weight: bold;
    font-size: 16px;
}

.restaurant-tab:hover, .reward-tab:hover, .reward-history-tab:hover {
    border: 3px solid #939191;
}

.reward-history-tab.labels:hover {
    border: 2px solid black;
}

.restaurant-tab-item:nth-of-type(1) {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reward-tab-item:nth-of-type(1), .reward-history-tab-item:nth-of-type(1) {
    width: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 15px;
}

.restaurant-tab-item:nth-of-type(2) {
    width: 70%;
}

.reward-tab-item:nth-of-type(2), .reward-history-tab-item:nth-of-type(2) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.restaurant-tab-item:nth-of-type(3) {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reward-tab-item:nth-of-type(3), .reward-history-tab-item:nth-of-type(3) {
    width: 35%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 15px;
}

.restaurant-tab-item p {
    font-size: 18px;
}

.restaurant-tab:last-child {
    margin-bottom: 20px;
}

#arrow {
    height: 22px;
    width: 22px;
}

#dustbin-icon {
    height: 22px;
    width: 22px;
}

#delete-button {
    background-color: white;
    border: none;
}

#add-restaurant-button {
    width: 160px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    color: #8E2121;
    background-color: white;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
}

#add-restaurant-button:hover {
    background-color: #8E2121;
    color: white;
    border: 1px solid black;
}

.scrollable {
    overflow-y: scroll;
}

.add-restaurant-container {
    height: 100%;
    width: 100%;
}

.add-restaurant-item:nth-of-type(1) {
    height: 10%;
    width: 100%;
    background-color: #8E2121;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 30px; */
}

.add-restaurant-logo-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 10px;
}

.add-restaurant-item:nth-of-type(2) {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-restaurant-subitem:nth-of-type(1) {
    height: 15%;
    width: 100%;
    font-size: 22px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 5%;
    /* font-weight: bold; */
}

.add-restaurant-subitem:nth-of-type(2) {
    height: 85%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.add-restaurant-subitem:nth-of-type(2) #back-button {
    margin-top: 30px;
}

.add-restaurant-form {
    border: 1px solid black;
    border-radius: 10px;
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-restaurant-form-item:nth-of-type(1) {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-restaurant-form-item:nth-of-type(2) {
    height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.add-restaurant-form input {
    width: 80%;
    border-bottom: 1px solid #939191;
}

#back-icon {
    height: 25px;
    width: 25px;
}

#final-add-restaurant-button {
    width: 95px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    color: #8E2121;
    background-color: white;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
    font-weight: bold;
}

#final-add-restaurant-button:hover {
    background-color: #8E2121;
    color: white;
    border: 1px solid black;
}

/* customer-points.html styling */

.points-container {
    height: 60%;
    width: 90%;
    border: 1px solid black;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.points-item:nth-of-type(1) {
    width: 90%;
    height: 25%;
    border-bottom: 1px solid #939191;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.points-item:nth-of-type(2) {
    width: 90%;
    height: 50%;
    border-bottom: 1px solid #939191;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.points-item:nth-of-type(3) {
    width: 90%;
    height: 25%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.points-item p {
    display: inline;
}

.color-red {
    color: #8E2121;
}

.back-button-container, .reward-history-button-container{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward-history-button-container {
    height: 10%;
}

.reward-history-main, .approve-rewards-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reward-history-container, .approve-rewards-container {
    height: 70%;
    width: 100%;
}

.reward-history-item:nth-of-type(1), .approve-rewards-item:nth-of-type(1) {
    height: 25%;
    width: 100%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reward-history-item:nth-of-type(2), .approve-rewards-item:nth-of-type(2) {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#back-button {
    width: 95px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    color: #8E2121;
    background-color: white;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
}

#back-button:hover {
    border: 1px solid black;
    color: white;
    background-color: #8E2121;
}

/* landing-page.html styling */
.sign-up-container {
    height: 45%;
    width: 90%;
    border: 1px solid black;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.sign-up-item:nth-of-type(1) {
    height: 30%;
    width: 90%;
    border-bottom: 1px solid #939191;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-item:nth-of-type(1) p {
    font-size: 22px;
}

.sign-up-item:nth-of-type(2) {
    height: 35%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 20px; */
}

.sign-up-item:nth-of-type(3) {
    height: 35%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 20px; */
}

#restaurant-sign-up-button,
#customer-sign-up-button {
    width: 160px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    background-color: white;
    color: #8E2121;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
}

#restaurant-sign-up-button:hover,
#customer-sign-up-button:hover {
    background-color: #8E2121;
    border: 1px solid black;
    color: white;
}

.padding-top-50 {
    padding-top: 50px;
}

.sign-up-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-form-item:nth-of-type(1) {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-form-item:nth-of-type(2) {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-form-item:nth-of-type(3) {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 15px;
}

#restaurant-sign-up-form, #customer-sign-up-form {
    width: 90%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.restaurant-form-input-container {
    height: 12%;
    width: 80%;
    display: flex;
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #939191;
}

.customer-form-input-container {
    height: 12%;
    width: 80%;
    display: flex;
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #939191;
}

.restaurant-form-input-container:last-of-type {
    border-bottom: none;
    justify-content: center;
    align-items: end;
}

.customer-form-input-container:last-of-type {
    height: 18%;
    border-bottom: none;
    justify-content: center;
    align-items: end;
}

#register-restaurant-button, #register-customer-button, #login-customer-button, #create-program-button, #claim-reward-button, #link-to-approve-rewards-button, #approve-all-rewards, #approve-single-reward, #reward-history-button {
    width: 100px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #8E2121;
    color: #8E2121;
    background-color: white;
    vertical-align: middle;
    box-shadow: 3px 3px 5px 3px #EAEAEA;
}

#register-restaurant-button:hover, #register-customer-button:hover, #login-customer-button:hover, #create-program-button:hover, #claim-reward-button:hover, #link-to-approve-rewards-button:hover, #approve-all-rewards:hover, #approve-single-reward:hover, #reward-history-button:hover {
    border: 1px solid black;
    color: white;
    background-color: #8E2121;
}

#claim-reward-button {
    width: 160px;
}

#link-to-approve-rewards-button, #reward-history-button {
    width: 160px;
    margin-top: 25px;
    font-size: 14px;
}

#approve-all-rewards {
    width: 150px;
    font-size: 14px;
    margin-bottom: 25px;
}

#approve-single-reward {
    width: 75px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sign-up-text {
    font-size: 18px;
}

.approval-pending-text {
    font-size: 16px;
    text-align: center;
}

.login-form-container, .loyalty-form-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.login-form-item:nth-of-type(1), .loyalty-form-item:nth-of-type(1) {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 20px;
}

.login-form-item:nth-of-type(2), .loyalty-form-item:nth-of-type(2) {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

#login-form, #loyalty-form {
    width: 90%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form-input-container {
    height: 25%;
    width: 80%;
    display: flex;
    justify-content: start;
    align-items: end;
    border-bottom: 1px solid #939191;
}

.loyalty-form-input-super-container {
    height: 25%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
}

.loyalty-form-input-container {
    height: 40%;
    width: 100%;
    border-bottom: 1px solid #939191;
    display: flex;
    justify-content: end;
}

.loyalty-form-input-super-container:last-of-type .loyalty-form-input-container {
    border: none;
    justify-content: center;
    align-items: end;
}

.loyalty-form-input-super-container p {
    margin-top: 5px;
}

.login-form-input-container:last-of-type {
    border-bottom: none;
    justify-content: center;
    align-items: end;
}

.extra-information {
    font-size: 12px;
}

.sign-up-success-main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 40px;
}

.sign-up-success-container {
    height: 50%;
    width: 90%;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-up-success-item:nth-of-type(1) {
    width: 90%;
    height: 20%;
    text-wrap: balance;
    text-align: center;
}

.sign-up-success-item:nth-of-type(2) {
    width: 90%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.sign-up-success-item:nth-of-type(3) {
    display: flex;
    height: 20%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.no-rewards-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
}

.no-rewards-item {
    height: 20%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
}

.text-align-center {
    text-align: center;
}

.desktop-heading {
    display: none;
}

/* Desktop styling */
@media (min-width: 768px) {
    .desktop-heading {
        display: block;
    }

    /* customer-index.html styling */
    .mobile-header-container {
        display: none;
    }

    .desktop-header-container {
        height: 15%;
        background-color: #8E2121;
        display: flex;
        border-bottom: 2px solid black;
    }

    .desktop-header-item:nth-of-type(1) {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 40px;
    }

    .desktop-header-item:nth-of-type(2) {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desktop-header-item:nth-of-type(3) {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 40px;
    }

    .no-footer-main-container {
        height: 85%;
        width: 100%;
    }

    .desktop-navigation ul {
        list-style-type: none;
        font-size: 18px;
        padding: 0;
    }

    .desktop-navigation ul li {
        color: white;
        display: inline;
        margin: 10px;
    }

    .desktop-navigation ul li:hover {
        color: #f3c4c4;
    }

    #logo {
        max-height: 60%;
        max-width: 60%;
    }

    .heading:nth-of-type(1) {
        display: none;
    }

    .heading:nth-of-type(2) {
        display: none;
    }

    .restaurant-tab, .reward-tab, .reward-history-tab, .reward-history-tab.labels {
        width: 50%;
    }

    .delete-window {
        height: 250px;
        width: 450px;
        margin-left: -225px;
    }

    .no-footer-main-item:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .desktop-heading {
        visibility: visible;
        font-size: 28px;
    }

    .add-restaurant-item:nth-of-type(1) {
        height: 15%;
        justify-content: start;
        /* padding-left: 40px; */
    }

    .add-restaurant-item:nth-of-type(2) {
        height: 85%;
    }

    .add-restaurant-logo-container {
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        padding-left: 40px;
    }

    .add-points-container {
        width: 50%;
        height: 60%;
    }

    .add-restaurant-subitem:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .add-restaurant-form {
        width: 50%;
    }

    /* customer-points.html */
    .points-container {
        width: 50%;
    }

    /* landing-page.html styling */
    .sign-up-container {
        width: 50%;
        height: 60%;
    }

    #restaurant-sign-up-button,
    #customer-sign-up-button {
        width: 200px;
        height: 40px;
        font-size: 20px;
    }

    .sign-up-item:nth-of-type(1) p {
        font-size: 24px;
    }

    #restaurant-sign-up-form, #customer-sign-up-form, #login-form, #loyalty-form {
        width: 40%;
        height: 100%;
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sign-up-text {
        font-size: 24px;
    }

    .extra-information {
        font-size: 14px;
    }

    .sign-up-success-container {
        height: 50%;
        width: 50%;
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .approval-pending-text {
        font-size: 18px;
    }

    .no-rewards-item {
        height: 20%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 10px;
        text-align: center;
        font-size: 18px;
    }

    .reward-tab-item:nth-of-type(1) {
        width: 15%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 25px;
    }

    .reward-tab-item:nth-of-type(2) {
        width: 50%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 10px;
    }

    .reward-tab-item:nth-of-type(3) {
        width: 35%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 25px;
    }

    .reward-history-item:nth-of-type(1), .approve-rewards-item:nth-of-type(1) {
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .res-id-statement {
        font-size: 22px;
        margin-top: 10px;
        font-weight: normal;
    }
}